import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();
const authChannel = new BroadcastChannel('auth-channel');

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        const savedUser = sessionStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });
    
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        return sessionStorage.getItem('isAuthenticated') === 'true';
    });

    useEffect(() => {
        authChannel.onmessage = (event) => {
            if (event.data.type === 'logout') {
                sessionStorage.removeItem('user');
                sessionStorage.removeItem('isAuthenticated');
                setUser(null);
                setIsAuthenticated(false);
            } else if (event.data.type === 'login') {
                if (event.data.user) {
                    sessionStorage.setItem('user', JSON.stringify(event.data.user));
                    sessionStorage.setItem('isAuthenticated', 'true');
                    setUser(event.data.user);
                    setIsAuthenticated(true);
                }
            }
        };
    }, []);

    const loginContext = (userData) => {
        sessionStorage.setItem('user', JSON.stringify(userData));
        sessionStorage.setItem('isAuthenticated', 'true');
        setUser(userData);
        setIsAuthenticated(true);
        authChannel.postMessage({ type: 'login', user: userData });
    };

    const logoutContext = () => {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('isAuthenticated');
        setUser(null);
        setIsAuthenticated(false);
        authChannel.postMessage({ type: 'logout' });
    };

    useEffect(() => {
        if (user) {
            authChannel.postMessage({ type: 'logout' });
            sessionStorage.setItem('user', JSON.stringify(user));
            sessionStorage.setItem('isAuthenticated', 'true');
        }
    }, [user]);

    return (
        <AuthContext.Provider value={{ user, loginContext, logoutContext, isAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
