import React, { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { AuthContext } from "../../../AuthContext";
import { Modal, Button, Form, Tabs, Tab, Table } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import EXIF from 'exif-js';
import exportCSV from 'json-to-csv-export';
import { refreshTokenRequest } from '../../refreshTokenRequest';
import LocationSelect from '../../shared/LocationSelect'
import LocationSelect2 from '../../shared/LocationSelect2'
import validator from 'validator';
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom';


const PemdaApprovalPendataan = () => {
    let refreshToken = null;
    const navigate = useNavigate()
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [totalRows, setTotalRows] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');

    const [selectedProvince, setSelectedProvince] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedVillage, setSelectedVillage] = useState('');
    const [wilayahPilih, setWilayahPilih] = useState('');

    const [selectedProvince2, setSelectedProvince2] = useState('');
    const [selectedCity2, setSelectedCity2] = useState('');
    const [selectedDistrict2, setSelectedDistrict2] = useState('');
    const [selectedVillage2, setSelectedVillage2] = useState('');
    const [wilayahPilih2, setWilayahPilih2] = useState('');

    const { user } = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const urlGambar = process.env.REACT_APP_API_GAMBAR;

    const [selectedRows, setSelectedRows] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [activeTab, setActiveTab] = useState('first');
    const [viewOnly, setViewOnly] = useState(false);


    const handleModalClose = () => {
        setShowModal(false);
    };

    const [showRejectModal, setShowRejectModal] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [selectedRow, setSelectedRow] = useState(null); // To store the row data


    const [selectedFiles, setSelectedFiles] = useState({
        photoDiri: null,
        photoSekitar: null,
        photoRumah: null,
        photoLahan: null
    });


    const handleVillageChange = (villageId) => {
        setSelectedVillage(villageId);
        setEditData(prevData => ({
            ...prevData,
            kodeWilayah: villageId
        }));

    };


    const handleModalSave = async () => {


        if (!selectedVillage) {
            alert('Kode wilayah harus dipiih');
            return

        }

        if (!editData.nik) {
            alert('Nik harusdi isi');
            return
        }


        if (editData.nik.length != 16) {
            alert('Nik harus 16 digit');
            return
        }

        if (!editData.nomorKk) {
            alert('Nomor KK harus diisi');
            return
        }

        if (editData.nomorKk.length != 16) {
            alert('Nomor KK harus 16 digit');
            return
        }

        if (!editData.jenisKelamin) {
            alert('Jenis Kelamin harus diisi');
            return
        }

        if (editData.jenisKelamin.length < 1) {
            alert('Jenis Kelamin harus diisi');
            return
        }

        // Check if any selected file is missing geoData
        // if (selectedFiles.photoDiri && !geoData.photoDiri) {
        //     alert('GeoTag untuk foto diri tidak ditemukan');
        //     return;
        // }
        // if (selectedFiles.photoSekitar && !geoData.photoSekitar) {
        //     alert('GeoTag untuk foto sekitar tidak ditemukan');
        //     return;
        // }
        // if (selectedFiles.photoRumah && !geoData.photoRumah) {
        //     alert('GeoTag untuk foto rumah tidak ditemukan');
        //     return;
        // }
        // if (selectedFiles.photoLahan && !geoData.photoLahan) {
        //     alert('GeoTag untuk foto lahan tidak ditemukan');
        //     return;
        // }

        setLoading(true); // Show the spinner

        const formData = new FormData();
        if (selectedFiles.photoDiri) {
            formData.append('photoDiri', selectedFiles.photoDiri);
            // Append geoData for photoDiri
            if (geoData.photoDiri) {
                formData.append('photoDiriLat', geoData.photoDiri.lat);
                formData.append('photoDiriLon', geoData.photoDiri.lon);
            }
        }
        if (selectedFiles.photoSekitar) {
            formData.append('photoSekitar', selectedFiles.photoSekitar);
            // Append geoData for photoSekitar
            if (geoData.photoSekitar) {
                formData.append('photoSekitarLat', geoData.photoSekitar.lat);
                formData.append('photoSekitarLon', geoData.photoSekitar.lon);
            }
        }
        if (selectedFiles.photoRumah) {
            formData.append('photoRumah', selectedFiles.photoRumah);
            // Append geoData for photoRumah
            if (geoData.photoRumah) {
                formData.append('photoRumahLat', geoData.photoRumah.lat);
                formData.append('photoRumahLon', geoData.photoRumah.lon);
            }
        }
        if (selectedFiles.photoLahan) {
            formData.append('photoLahan', selectedFiles.photoLahan);
            // Append geoData for photoLahan
            if (geoData.photoLahan) {
                formData.append('photoLahanLat', geoData.photoLahan.lat);
                formData.append('photoLahanLon', geoData.photoLahan.lon);
            }
        }

        try {
            // First request: update `editData`
            const semuaResponse = await axios.put(`${apiUrl}/api/igahp/quesioner/updateSemua/${editData.id}`, editData, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            // Second request: update photos and geotags
            await axios.put(`${apiUrl}/api/igahp/quesioner/updatePhoto/${semuaResponse.data.data}`,
                formData, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            await axios.put(`${apiUrl}/api/igahp/quesioner/updateStatus/${semuaResponse.data.data}/3`,
                {}, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'Content-Type': 'multipart/form-data'

                }
            });


            // Close modal and refresh data
            setShowModal(false);
            fetchData();

        } catch (error) {
            console.error('Error updating data:', error);
        } finally {
            setLoading(false); // Hide the spinner
        }
    };


    const handleShowModal = () => {
        const selectedData = data.filter(row => selectedRows.includes(row.id));
        setSelectedData(selectedData);
        setShowModal(true);
    };

    const handleRowSelect = (id) => {
        setSelectedRows(prev =>
            prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
        );
    };

    const [surveyors, setSurveyors] = useState([]);
    const [selectedSurveyor, setSelectedSurveyor] = useState('');

    const [geoData, setGeoData] = useState({
        photoDiri: null,
        photoSekitar: null,
        photoRumah: null,
        photoLahan: null
    });


    const [previewImages, setPreviewImages] = useState({});
    const [editData, setEditData] = useState({ id: '', nik: '', namaLengkap: '', handphone: '' });




    useEffect(() => {
        if (wilayahPilih2) {
            fetchData();
        }
    }, [page, perPage, searchQuery, wilayahPilih2]); // Consolidated dependencies



    useEffect(() => {

        if (selectedProvince2) {
            setWilayahPilih2(selectedProvince2)
        }
    }, [selectedProvince2]);

    useEffect(() => {
        if (selectedCity2) {
            setWilayahPilih2(selectedCity2)
        }
    }, [selectedCity2]);

    useEffect(() => {
        if (selectedDistrict2) {
            setWilayahPilih2(selectedDistrict2)
        }
    }, [selectedDistrict2]);

    useEffect(() => {
        if (selectedVillage2) {
            setWilayahPilih2(selectedVillage2)
        }
    }, [selectedVillage2]);



    const columns = [

        {
            name: 'No.',
            selector: (row, index) => (page - 1) * perPage + index + 1,
            sortable: true,
            width: '80px', // Set a reasonable width for small columns
        },

        {
            name: 'Kelengkapan',
            selector: (row) => {
                const totalColumns = 32; // Jumlah total kolom yang relevan (tidak termasuk kolom aksi atau kolom lainnya)
                const filledColumns = [
                    row.kodewilayah,
                    row.nik,
                    row.namaLengkap,
                    row.jenisKelamin,
                    row.statusKawin,
                    row.nomorHandphone,
                    row.rt,
                    row.rw,
                    row.nomorKk,
                    row.alamatDomisili,
                    row.tanggalLahir,
                    row.pekerjaan,
                    row.statusKepemilikanRumah,
                    row.luasTanah,
                    row.luasBangunan,
                    row.jenisAtap,
                    row.jenisDinding,
                    row.jenisLantai,
                    row.penerangan,
                    row.airMinum,
                    row.jenisKloset,
                    row.minatProgrampembiayaan,
                    row.jenisPeminatanrumah,
                    row.programPerumahan,
                    row.pilihanBankpelaksana,
                    row.besaranCicilan,
                    row.rencanaProgram,
                    row.photoDiriPath,
                    row.photoSekitarPath,
                    row.photoRumahPath,
                    row.photoLahanPath,
                    row.penghasilan,
                ].filter(
                    (value) => value !== null && value !== undefined && value.toString().trim() !== ''
                ).length;

                // Hitung persentase kelengkapan
                return (filledColumns / totalColumns) * 100;
            },
            cell: (row) => {
                const totalColumns = 32; // Jumlah total kolom yang relevan
                const filledColumns = [
                    row.kodewilayah,
                    row.nik,
                    row.namaLengkap,
                    row.jenisKelamin,
                    row.statusKawin,
                    row.nomorHandphone,
                    row.rt,
                    row.rw,
                    row.nomorKk,
                    row.alamatDomisili,
                    row.tanggalLahir,
                    row.pekerjaan,
                    row.statusKepemilikanRumah,
                    row.luasTanah,
                    row.luasBangunan,
                    row.jenisAtap,
                    row.jenisDinding,
                    row.jenisLantai,
                    row.penerangan,
                    row.airMinum,
                    row.jenisKloset,
                    row.minatProgrampembiayaan,
                    row.jenisPeminatanrumah,
                    row.programPerumahan,
                    row.pilihanBankpelaksana,
                    row.besaranCicilan,
                    row.rencanaProgram,
                    row.photoDiriPath,
                    row.photoSekitarPath,
                    row.photoRumahPath,
                    row.photoLahanPath,
                    row.penghasilan,
                ].filter(
                    (value) => value !== null && value !== undefined && value.toString().trim() !== ''
                ).length;

                const completeness = (filledColumns / totalColumns) * 100;

                let badgeClass = 'badge badge-secondary'; // Default (gelap)

                if (completeness === 100) {
                    badgeClass = 'badge badge-success'; // Hijau untuk lengkap
                } else if (completeness >= 75) {
                    badgeClass = 'badge badge-info'; // Biru untuk hampir lengkap
                } else if (completeness >= 50) {
                    badgeClass = 'badge badge-warning'; // Kuning untuk cukup lengkap
                } else {
                    badgeClass = 'badge badge-danger'; // Merah untuk tidak lengkap
                }

                return (
                    <span className={badgeClass}>
                        {Math.round(completeness)}%
                    </span>
                );
            },
            sortable: true, // Mengaktifkan sorting
            width: '120px', // Lebar kolom
            ignoreRowClick: true, // Hindari interaksi pada klik baris
        }
        ,
        {
            name: 'Kab/Kota',
            selector: row => row.namaKabkota,
            sortable: true,
            wrap: true, // Wrap text in case it overflows
        },

        {
            name: 'Kecamatan',
            selector: row => row.namaKec,
            sortable: true,
            wrap: true, // Wrap text in case it overflows
        },
        {
            name: 'Nama Lengkap',
            selector: row => row.namaLengkap,
            sortable: true,
            wrap: true, // Wrap text in case it overflows
        },
        {
            name: 'NIK',
            selector: row => {
                const nik = row.nik;
                return nik ? nik.slice(0, 12) + nik.slice(12).replace(/\d/g, '*') : '';
            },
            sortable: true,
            wrap: true,
        },
        {
            name: 'Jenis Kelamin',
            selector: row => row.jenisKelamin == '1' ? 'Pria' : row.jenisKelamin == '2' ? 'Wanita' : 'Unknown',
            sortable: true,
            wrap: true,
        },
        {
            name: 'Alamat',
            selector: row => row.alamatKtp,
            sortable: true,
            wrap: true, // Wrap text in case it overflows
        },
        {
            name: 'Status',
            selector: row => {
                switch (row.status) {
                    case '0':
                        return 'Data Awal';
                    case '1':
                        return 'Proses Checking';
                    case '2a':
                        return 'Lolos Checking';
                    case '2b':
                        return 'Tidak Lolos Checking';
                    case '3':
                        return 'Menunggu Approval';
                    case '4':
                        return 'Revisi';
                    case '5':
                        return 'Approved';
                    default:
                        return 'Unknown';
                }
            },
            sortable: true,
            wrap: true, // Wrap text in case it overflows
            cell: row => {
                let statusText;
                let badgeClass;

                switch (row.status) {
                    case '0':
                        statusText = 'Data Awal';
                        badgeClass = 'badge badge-primary'; // Yellow color for pending
                        break;
                    case '1':
                        statusText = 'Proses Checking';
                        badgeClass = 'badge badge-warning'; // Yellow color for pending
                        break;
                    case '2a':
                        statusText = 'Lolos Checking';
                        badgeClass = 'badge badge-warning'; // Yellow color for pending
                        break;
                    case '2b':
                        statusText = 'Tidak Lolos Checking';
                        badgeClass = 'badge badge-danger'; // Red color for revision
                        break;
                    case '3':
                        statusText = 'Menunggu Approval';
                        badgeClass = 'badge badge-secondary'; // Red color for revision
                        break;
                    case '4':
                        if (row.editCount === 0) {
                            statusText = 'Revisi';
                        } else {
                            statusText = 'Revisi Ke-' + row.editCount;
                        }
                        badgeClass = 'badge badge-danger'; // Merah untuk revisi
                        break;

                    case '5':
                        statusText = 'Approved';
                        badgeClass = 'badge badge-success'; // Green color for approval
                        break;
                    default:
                        statusText = 'Unknown';
                        badgeClass = 'badge badge-secondary'; // Grey color for unknown
                }

                // Return the status text inside a Bootstrap badge
                return <span className={badgeClass}>{statusText}</span>;
            }
        },
        {
            name: 'Nama Enumerator',
            selector: row => row.namaSurveyor,
            sortable: true,
            wrap: true, // Wrap text in case it overflows
        },
        {
            name: 'Dibuat oleh',
            selector: row => row.username,
            sortable: true,
            wrap: true, // Wrap text in case it overflows
        },
        {
            name: 'Di Edit oleh',
            selector: row => (row.status === '3' || row.status === '4') ? row.usernameEdit : '', // Tampilkan hanya jika status = '3' atau '4'
            sortable: true,
            wrap: true, // Bungkus teks jika melebihi lebar kolom
        },

        {
            name: 'Aksi',
            cell: (row) => (
                <div>
                    <button
                        className="btn btn-info btn-sm"
                        onClick={() => {
                            setViewOnly(true);
                            handleEdit(row);
                        }}
                    >
                        <i className="fa fa-eye"></i>
                    </button>


                    <button
                        className="btn btn-danger btn-sm ml-2"
                        title="Revisi" // Tooltip for reject button
                        onClick={() => {
                            // handleReject(row);
                            handleRevisiReject(row);
                        }}
                    >
                        <i className="fa fa-comment"></i> {/* Reject */}
                    </button>

                    <button
                        className="btn btn-success btn-sm ml-2"
                        title="Approve" // Tooltip for approve button
                        onClick={() => {
                            handleApprove(row);
                        }}
                    >
                        <i className="bi bi-check-circle-fill"></i> {/* Approve */}
                    </button>

                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '250px', // Adjusted width to fit four buttons
        }

    ];


    const fetchData = async () => {
        setLoading(true);


        try {
            const sanitizedSearchQuery = validator.escape(searchQuery); // Escape query untuk keamanan

            const response = await axios.get(`${apiUrl}/api/igahp/quesioner/pendataanNeedApproveByPemda`, {
                params: {
                    page: page - 1,
                    size: perPage,
                    wilayah: wilayahPilih2, // Kirim wilayah yang sudah digabung
                    search: encodeURIComponent(sanitizedSearchQuery), // Encode search query
                },
                headers: {
                    Authorization: `Bearer ${user.accessToken}`,
                },
            });
            setData(response.data.content);
            setTotalRows(response.data.totalElements);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchAllData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/api/igahp/quesioner/data-quesioner-page2-all`, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,  // Replace `yourToken` with the actual token
                }
            });
            setLoading(false);
            return response.data;
        } catch (error) {
            console.error('Error fetching all data:', error);
            setLoading(false);
            return [];
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, perPage, searchQuery]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setPage(1);
    };

    // const handleInputChange = (e) => {
    //     const { name, type, checked, value } = e.target;
    //     setEditData(prevData => ({
    //         ...prevData,
    //         [name]: type === 'checkbox' ? checked : value
    //     }));
    // };

    const handleInputChange = (e) => {
        const { name, type, checked, value } = e.target;

        setEditData((prevData) => {
            // Jika "Tidak Ada Pembangunan Swadaya Masyarakat" dipilih, reset checkbox lainnya
            if (name === 'pembangunanTidakAdaSwadayaMasyarakat' && type === 'checkbox') {
                return {
                    ...prevData,
                    [name]: checked,
                    pembangunanTabunganUang: false,
                    pembangunanTabunganMaterial: false,
                    pembangunanTenagaKerja: false,
                };
            }

            // Jika "Tidak Ada Perbaikan Swadaya Masyarakat" dipilih, reset checkbox lainnya
            if (name === 'perbaikanTidakAdaSwadayaMasyarakat' && type === 'checkbox') {
                return {
                    ...prevData,
                    [name]: checked,
                    perbaikanTabunganUang: false,
                    perbaikanTabunganMaterial: false,
                    perbaikanTenagaKerja: false,
                };
            }

            // Jika checkbox lain dipilih, pastikan "Tidak Ada Pembangunan Swadaya Masyarakat" di-reset
            if (
                (name === 'pembangunanTabunganUang' ||
                    name === 'pembangunanTabunganMaterial' ||
                    name === 'pembangunanTenagaKerja') &&
                type === 'checkbox' &&
                checked
            ) {
                return {
                    ...prevData,
                    [name]: checked,
                    pembangunanTidakAdaSwadayaMasyarakat: false,
                };
            }

            // Jika checkbox lain dipilih, pastikan "Tidak Ada Perbaikan Swadaya Masyarakat" di-reset
            if (
                (name === 'perbaikanTabunganUang' ||
                    name === 'perbaikanTabunganMaterial' ||
                    name === 'perbaikanTenagaKerja') &&
                type === 'checkbox' &&
                checked
            ) {
                return {
                    ...prevData,
                    [name]: checked,
                    perbaikanTidakAdaSwadayaMasyarakat: false,
                };
            }

            // Default update untuk semua input lainnya
            return {
                ...prevData,
                [name]: type === 'checkbox' ? checked : value,
            };
        });
    };


    const handleApprove = async (row) => {
        if (window.confirm("Are you sure you want to approve this item? ")) {

            try {
                const response = await axios.put(
                    `${apiUrl}/api/igahp/quesioner/updateStatus/${row.id}/5`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );

                fetchData();
                // Menampilkan pesan berhasil (response.data berisi respon sukses dari server)
                console.log('Response:', response.data);
                alert(response.data.data);
            } catch (error) {
                alert(error.response.data.data);
                if (error.response) {
                    // Jika respons dari server dengan status error, gunakan data dari response body
                    const errorMessage = error.response.data?.message || 'Unknown error occurred';
                    const errorCode = error.response.status;

                    console.error('Error:', {
                        code: errorCode,
                        message: errorMessage
                    });
                    // alert(errorMessage)
                } else {
                    alert(error.message)
                    // Jika terjadi kesalahan lain (contoh: tidak ada respons dari server)
                    console.error('Error:', error.message);
                }
            }
        } else {
            console.log("Approval canceled");
        }
    };

    const handleReject = async (row) => {
        // if (window.confirm("Are you sure you want to reject this item?")) {
        //     await axios.put(`${apiUrl}/api/igahp/quesioner/updateStatus/${row.id}/4`,
        //         {}, {
        //         headers: {
        //             Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
        //             'Content-Type': 'multipart/form-data'

        //         }
        //     });
        //     fetchData();
        // } else {
        //     // Jika user menekan "No"
        //     console.log("Rejection canceled");
        // }
        setSelectedRow(row); // Store the row data
        setShowRejectModal(true); // Show the rejection modal
    };

    const handleEdit = (row) => {
        const formattedTanggalLahir = new Date(row.tanggalLahir).toISOString().split('T')[0];


        setGeoData({})
        setSelectedVillage(row.kodewilayah);
        // setRowData(row);

        setPreviewImages({
            photoDiri: row.photoDiriPath
                ? row.photoDiriPath.startsWith("server2")
                    ? `${urlGambar}/api/public/displayimage/${row.photoDiriPath}`
                    : `${apiUrl}/api/public/displayimage/${row.photoDiriPath}`
                : '/dist/img/no-photo-choose.png',

            photoSekitar: row.photoSekitarPath
                ? row.photoSekitarPath.startsWith("server2")
                    ? `${urlGambar}/api/public/displayimage/${row.photoSekitarPath}`
                    : `${apiUrl}/api/public/displayimage/${row.photoSekitarPath}`
                : '/dist/img/no-photo-choose.png',
                
            photoRumah: row.photoRumahPath
                ? row.photoRumahPath.startsWith("server2")
                    ? `${urlGambar}/api/public/displayimage/${row.photoRumahPath}`
                    : `${apiUrl}/api/public/displayimage/${row.photoRumahPath}`
                : '/dist/img/no-photo-choose.png',
                
            photoLahan: row.photoLahanPath
                ? row.photoLahanPath.startsWith("server2")
                    ? `${urlGambar}/api/public/displayimage/${row.photoLahanPath}`
                    : `${apiUrl}/api/public/displayimage/${row.photoLahanPath}`
                : '/dist/img/no-photo-choose.png',
                
        });


        setEditData(
            {
                id: row.id,
                namaDesa: row.namaDesa,
                rw: row.rw,
                rt: row.rt,
                alamatKtp: row.alamatKtp,
                alamatDomisili: row.alamatDomisili,
                nik: row.nik,
                namaLengkap: row.namaLengkap,
                nomorHandphone: row.nomorHandphone,
                nomorKk: row.nomorKk,
                jenisKelamin: row.jenisKelamin,
                tanggalLahir: formattedTanggalLahir,
                statusKawin: row.statusKawin,
                agama: row.agama,
                pendidikan: row.pendidikan,
                pekerjaan: row.pekerjaan,
                penghasilan: row.penghasilan,
                memilikiTabungan: row.memilikiTabungan,
                statusKepemilikanRumah: row.statusKepemilikanRumah,
                buktiKepemilikanRumah: row.buktiKepemilikanRumah,
                jumlahPenghuni: row.jumlahPenghuni,
                luasTanah: row.luasTanah,
                luasBangunan: row.luasBangunan,
                jenisAtap: row.jenisAtap,
                jenisDinding: row.jenisDinding,
                jenisLantai: row.jenisLantai,
                penerangan: row.penerangan,
                airMinum: row.airMinum,
                jenisKloset: row.jenisKloset,
                bbMemasak: row.bbMemasak,
                layakKonstruksi: row.layakKonstruksi,
                layakDensitas: row.layakDensitas,
                layakAirminum: row.layakAirminum,
                layakSanitasi: row.layakSanitasi,
                asetLahanlain: row.asetLahanlain,
                asetRumahlain: row.asetRumahlain,
                minatProgrampembiayaan: row.minatProgrampembiayaan,
                jenisPeminatanrumah: row.jenisPeminatanrumah,
                programPerumahan: row.programPerumahan,
                pilihanBankpelaksana: row.pilihanBankpelaksana,
                besaranCicilan: row.besaranCicilan,
                rencanaProgram: row.rencanaProgram,
                angkutanUmum: row.angkutanUmum,
                terminal: row.terminal,
                stasiun: row.stasiun,
                pasar: row.pasar,
                bank: row.bank,
                gerbangTol: row.gerbangTol,
                spbu: row.spbu,
                tk: row.tk,
                sd: row.sd,
                sltp: row.sltp,
                slta: row.slta,
                universitas: row.universitas,
                pendidikanLainnya: row.pendidikanLainnya,
                masjidMusholla: row.masjidMusholla,
                gereja: row.gereja,
                vihara: row.vihara,
                klenteng: row.klenteng,
                tempatIbadahLainnya: row.tempatIbadahLainnya,
                terjadiGenanganAir: row.terjadiGenanganAir,
                terjadiBanjir: row.terjadiBanjir,
                terjadiPutingBeliung: row.terjadiPutingBeliung,
                terjadiKeretakanTanah: row.terjadiKeretakanTanah,
                terjadiLongsor: row.terjadiLongsor,
                terjadiGempaBumi: row.terjadiGempaBumi,
                namaBankLain: row.namaBankLain,
                koordinatFotoDiri: row.koordinatFotoDiri,
                koordinatFotoLahan: row.koordinatFotoLahan,
                koordinatFotoRumah: row.koordinatFotoRumah,
                koordinatFotoSekitar: row.koordinatFotoSekitar,

                pembangunanTabunganUang: row.pembangunanTabunganUang,
                pembangunanTabunganMaterial: row.pembangunanTabunganMaterial,
                pembangunanTenagaKerja: row.pembangunanTenagaKerja,
                pembangunanTidakAdaSwadayaMasyarakat: row.pembangunanTidakAdaSwadayaMasyarakat,

                perbaikanRumahExisting: row.perbaikanRumahExisting,
                penambahanRuanganLuasan: row.penambahanRuanganLuasan,
                jenisPerbaikanLainnya: row.jenisPerbaikanLainnya,
                perbaikanTabunganUang: row.perbaikanTabunganUang,
                perbaikanTabunganMaterial: row.perbaikanTabunganMaterial,
                perbaikanTenagaKerja: row.perbaikanTenagaKerja,
                perbaikanTidakAdaSwadayaMasyarakat: row.perbaikanTidakAdaSwadayaMasyarakat,
                jumlahTanggungan: row.tanggunganJiwa,
                catatan: row.catatan,

            }
        );


        setShowModal(true);
    };

    const handleRejectSubmit = async () => {




        // Call the API to send rejection data
        try {

            await axios.put(`${apiUrl}/api/igahp/quesioner/updateStatus/${selectedRow.id}/4`,
                {}, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'Content-Type': 'multipart/form-data'

                }
            });


            const payload = {
                nik: selectedRow.nik, // Assuming 'nik' is a property in row
                revisinote: rejectionReason,
            };

            const response = await fetch(`${apiUrl}/api/igahp/quesioner/revisinote`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                // Handle success (e.g., close the modal, show a success message)
                fetchData();
                setShowRejectModal(false);
                setRejectionReason("")
                alert('Revisi  successfully!');
            } else {
                // Handle error
                alert('Error submitting revisi!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error submitting revisi!');
        }
    };


    const handleImageChange = (e, fieldName) => {
        const file = e.target.files[0];
        if (file) {

            const fileSize = file.size / 1024 / 1024; // Convert bytes to MB
            if (fileSize > 10) {
                alert("Ukuran file tidak boleh lebih dari 5MB");
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedFiles(prevState => ({
                    ...prevState,
                    [fieldName]: file
                }));
                setPreviewImages(prevState => ({
                    ...prevState,
                    [fieldName]: reader.result // Store the preview image data URL
                }));
            };
            reader.readAsDataURL(file); // Read the file as a data URL

            EXIF.getData(file, function () {
                const latitude = EXIF.getTag(this, 'GPSLatitude');
                const longitude = EXIF.getTag(this, 'GPSLongitude');
                const latitudeRef = EXIF.getTag(this, 'GPSLatitudeRef');
                const longitudeRef = EXIF.getTag(this, 'GPSLongitudeRef');

                if (latitude && longitude) {
                    const lat = convertDMSToDD(latitude, latitudeRef);
                    const lon = convertDMSToDD(longitude, longitudeRef);

                    // Update geoData with the geolocation information
                    setGeoData(prevState => ({
                        ...prevState,
                        [fieldName]: {
                            lat,
                            lon,
                            latitudeRef,
                            longitudeRef
                        }
                    }));

                    console.log('Geo data....')
                    console.log(geoData)
                } else {
                    alert('This photo does not contain geotag (location) information.');
                }
            });
        }
    };

    // Utility function to convert DMS (Degrees, Minutes, Seconds) to Decimal Degrees (DD)
    const convertDMSToDD = (dms, ref) => {
        const degrees = dms[0] + dms[1] / 60 + dms[2] / 3600;
        return ref === 'S' || ref === 'W' ? -degrees : degrees;
    };



    const handleExportCSV = async () => {
        const allData = await fetchAllData();
        const headers = ['No.', 'Nama Lengkap', 'NIK', 'Jenis Kelamin', 'Tanggungan Jiwa', 'Handphone'];
        const itemsFormatted = allData.map((item, index) => ({
            'No.': index + 1,
            'Nama Lengkap': item.namaLengkap,
            'NIK': item.nik,
            'Jenis Kelamin': item.jenisKelamin,
            'Tanggungan Jiwa': item.tanggunganJiwa,
            'Handphone': item.handphone,
        }));

        exportCSV({ data: itemsFormatted, filename: 'pendataan_awal_bgh_all', headers });
    };


    const handleSurveyorChange = (event) => {
        setSelectedSurveyor(event.target.value);
    };

    const handleSaveAssignment = async () => {

        // let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        // let currentDate = new Date();
        // // JWT exp is in seconds
        // if (decodedToken.exp * 1000 < currentDate.getTime()) {
        //     console.log("Token expired.");
        //     try {
        //         const paramRefreshToken = {
        //             refreshToken: user.refreshToken,
        //             csrfToken: user.csrfToken,
        //         };
        //         refreshToken = await refreshTokenRequest(paramRefreshToken);
        //         // refreshToken = await refreshTokenRequest();
        //     } catch (error) {
        //         console.error('Expired. Please sign in.');
        //         navigate('/login');
        //     }
        //     if (refreshToken.statusCode === 403) {
        //         console.error('Expired. Please sign in.');
        //         navigate('/login');
        //     }
        //     console.log(refreshToken);
        //     user['accessToken'] = refreshToken.accessToken;
        //     setIsTokenExpired(true);
        // } else {
        //     console.log("Valid token");
        //     // result = true;
        // }



        const payload = {
            surveyorId: selectedSurveyor,
            selectedData: selectedData, // This should contain the rows you've selected
        };

        try {
            const response = await fetch(`${apiUrl}/api/igahp/quesioner/assignSurveyor`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                alert("Assignment saved successfully!");
                setShowModal(false);
            } else {
                console.error("Failed to save assignment");
                alert("Failed to save assignment");
            }
        } catch (error) {
            console.error("Error:", error);
            alert("Error occurred while saving assignment");
        }
    };


    const handleNikBlur = () => {
        if (tambahData) {

            axios.get(`${apiUrl}/api/public/checkNikPendataan/${encodeURIComponent(editData.nik)}`)
                .then(response => {
                    if (response.data) {
                        alert('Nik sudah terdaftar');

                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            // alert('cek nik already exist or no');
        }
    }

    const [tambahData, setTambahData] = useState(false);
    const [formattedPenghasilan, setFormattedPenghasilan] = useState("");




    const [showRevisiModal, setShowRevisiModal] = useState(false);
    const [formRevisiData, setFormRevisiData] = useState({
        content: "",
        response: "",
        sudahDirespon: false,
    });

    const handleRevisiReject = (row) => {
        setSelectedRow(row); // Simpan data baris yang dipilih
        setShowRevisiModal(true); // Tampilkan modal
    };

    const handleInputRevisiChange = (e) => {
        const { name, value } = e.target;
        setFormRevisiData({ ...formRevisiData, [name]: value });
    };

    const handleRevisiSubmit = () => {
        // Simpan data ke backend menggunakan API
        const payload = {
            idQuesioner: selectedRow.id, // ID baris terkait
            content: formRevisiData.content,
            response: formRevisiData.response,
            sudahDirespon: formRevisiData.sudahDirespon,
        };

        console.log("Submitting payload:", payload);


        // Contoh API request
        fetch(`${apiUrl}/api/igahp/quesioner/catatan-revisi`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${refreshToken?.accessToken || user.accessToken}`,
            },
            body: JSON.stringify(payload),
        })
            .then((res) => {
                if (res.ok) {
                    fetchData();
                    alert("Revisi berhasil disimpan.");
                    setShowRevisiModal(false); // Tutup modal
                } else {
                    alert("Gagal menyimpan revisi.");
                }
            })
            .catch((err) => console.error("Error:", err));
    };


    const [revisiList, setRevisiList] = useState([]);

    const [loadingRevisi, setLoadingRevisi] = useState(false);

    // Fetch data ketika modal muncul
    useEffect(() => {
        if (showRevisiModal) {
            formRevisiData.content = "";
            fetchRevisiList();
        }
    }, [showRevisiModal]);

    const fetchRevisiList = async () => {
        try {
            setLoadingRevisi(true);
            const response = await fetch(`${apiUrl}/api/igahp/quesioner/getCatatanRevisi?quesionerId=${selectedRow.id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                }
            });
            const data = await response.json();
            setRevisiList(data);
        } catch (error) {
            console.error("Error fetching revisi list:", error);
        } finally {
            setLoadingRevisi(false);
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Approval Pendataan</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Approval Pendataan</li>
                                </ol>
                            </div>
                        </div>

                        <div className="form-group">
                            <h8>Pilih Wilayah</h8>
                            <div className="row">
                                <LocationSelect2
                                    onProvinceChange={setSelectedProvince2}
                                    onCityChange={setSelectedCity2}
                                    onDistrictChange={setSelectedDistrict2}
                                    onVillageChange={setSelectedVillage2}
                                />
                            </div>
                        </div>

                        <div className="row mb-2 d-flex justify-content-between">
                            <div className="col-sm-4 d-flex">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    className="form-control form-control-sm"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                />

                            </div>

                        </div>


                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <div className="d-flex align-items-center">
                                {/* <input
                                    type="checkbox"
                                    checked={selectedRows.length === data.length}
                                    onChange={() => {
                                        if (selectedRows.length === data.length) {
                                            setSelectedRows([]);
                                        } else {
                                            setSelectedRows(data.map(row => row.id));
                                        }
                                    }}
                                /> */}
                                {/* <span className="ml-2">Pilih Semua</span> */}
                            </div>
                            {/* 
                            <button
                                className="btn btn-info"
                                onClick={handleShowModal}
                                disabled={selectedRows.length === 0} // Disable if no rows are selected
                            >
                                Penugasan ke Enumerator
                            </button> */}

                        </div>

                        <DataTable
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={page => setPage(page)}
                            onChangeRowsPerPage={perPage => setPerPage(perPage)}
                            responsive
                            dense
                        />
                    </div>
                </section>
            </div>

            <Modal show={showModal} onHide={handleModalClose} size="xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {viewOnly ? "View Only" : editData.id === "0" ? "Tambah Data" : "Edit Data"}
                    </Modal.Title>
                </Modal.Header>


                <Modal.Body>
                    <Tabs
                        id="edit-data-tabs"
                        activeKey={activeTab}
                        onSelect={(k) => setActiveTab(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="first" title="Wilayah">
                            <Form>

                                <div className="form-group">
                                    <label>Pilih Wilayah</label>
                                    <div className="row">
                                        <LocationSelect id="locationSelect1" villageCode={selectedVillage} onVillageChange={handleVillageChange} />
                                    </div>
                                </div>


                                <Form.Group controlId="formNmDesa">
                                    <Form.Label>Nama Desa</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="namaDesa"
                                        value={editData.namaDesa}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formNmRt">
                                    <Form.Label>RT</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="rt"
                                        value={editData.rt}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formNmRw">
                                    <Form.Label>RW</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="rw"
                                        value={editData.rw}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formAlamatKtp">
                                    <Form.Label>Alamat Sesuai Ktp</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="alamatKtp"
                                        value={editData.alamatKtp}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formAlamatDomisili">
                                    <Form.Label>Alamat Domisili</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="alamatDomisili"
                                        value={editData.alamatDomisili}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Form>
                        </Tab>

                        <Tab eventKey="second" title="Demografi">
                            <Form>
                                <Form.Group controlId="formNama">
                                    <Form.Label>Nama Lengkap</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="namaLengkap"
                                        value={editData.namaLengkap}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formNik">
                                    <Form.Label>Nik</Form.Label>
                                    {editData.id == "0" ? (
                                        <Form.Control
                                            type="text"
                                            name="nik"
                                            value={editData.nik}
                                            onChange={handleInputChange}
                                            onBlur={handleNikBlur}
                                        />
                                    ) : (
                                        <Form.Control
                                            type="text"
                                            name="nik"
                                            value={`${editData.nik.slice(0, 12)}****`}
                                            readOnly
                                        />
                                    )}
                                </Form.Group>


                                <Form.Group controlId="formKk">
                                    <Form.Label>Nomor KK</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nomorKk"
                                        value={editData.nomorKk}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formHp">
                                    <Form.Label>Nomor Hp</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nomorHandphone"
                                        value={editData.nomorHandphone}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formJenisKelamin">
                                    <Form.Label>Jenis Kelamin</Form.Label>
                                    <Form.Select
                                        name="jenisKelamin"
                                        value={editData.jenisKelamin}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Jenis Kelamin</option>
                                        <option value="1">Laki-laki</option>
                                        <option value="2">Perempuan</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="formTanggalLahir">
                                    <Form.Label>Tanggal Lahir</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="tanggalLahir"
                                        value={editData.tanggalLahir}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>


                                {/* Status Perkawinan */}
                                <Form.Group controlId="formStatusPerkawinan">
                                    <Form.Label>Status Perkawinan</Form.Label>
                                    <Form.Select
                                        name="statusKawin"
                                        value={editData.statusKawin}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Status Perkawinan</option>
                                        <option value="1">Belum Kawin</option>
                                        <option value="2">Kawin</option>
                                        <option value="3">Cerai Hidup</option>
                                        <option value="4">Cerai Mati</option>
                                    </Form.Select>
                                </Form.Group>


                                <Form.Group controlId="formTanggungan">
                                    <Form.Label>Jumlah Tanggungan</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="jumlahTanggungan"
                                        value={editData.jumlahTanggungan}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                {/* Agama */}
                                <Form.Group controlId="formAgama">
                                    <Form.Label>Agama</Form.Label>
                                    <Form.Select
                                        name="agama"
                                        value={editData.agama}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Agama</option>
                                        <option value="1">Islam</option>
                                        <option value="2">Kristen</option>
                                        <option value="3">Katolik</option>
                                        <option value="4">Hindu</option>
                                        <option value="5">Buddha</option>
                                        <option value="6">Konghucu</option>
                                        <option value="7">Penghayat Kepercayaan</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="formPendidikan">
                                    <Form.Label>Pendidikan</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="pendidikan"
                                        value={editData.pendidikan}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Pendidikan</option>
                                        <option value="1">Tidak/Belum Sekolah</option>
                                        <option value="2">Tidak Tamat SD/Sederajat</option>
                                        <option value="3">Masih SD/Sederajat</option>
                                        <option value="4">Tamat SD/Sederajat</option>
                                        <option value="5">Masih SLTP/Sederajat</option>
                                        <option value="6">Tamat SLTP/Sederajat</option>
                                        <option value="7">Masih SLTA/Sederajat</option>
                                        <option value="8">Tamat SLTA/Sederajat</option>
                                        <option value="9">Masih PT/Sederajat</option>
                                        <option value="10">Tamat PT/Sederajat</option>
                                    </Form.Control>
                                </Form.Group>


                                <Form.Group controlId="formPekerjaan">
                                    <Form.Label>Pekerjaan</Form.Label>
                                    <Form.Select
                                        name="pekerjaan"
                                        value={editData.pekerjaan}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Pekerjaan</option>
                                        <option value="1">1. Tidak/Belum Bekerja</option>
                                        <option value="2">2. Petani</option>
                                        <option value="3">3. Nelayan</option>
                                        <option value="4">4. Pedagang</option>
                                        <option value="5">5. Pejabat Negara</option>
                                        <option value="6">6. PNS/TNI/Polri</option>
                                        <option value="7">7. Pegawai Swasta</option>
                                        <option value="8">8. Wiraswasta</option>
                                        <option value="9">9. Pensiunan</option>
                                        <option value="10">10. Pekerja Lepas</option>
                                    </Form.Select>
                                </Form.Group>


                                <Form.Group controlId="formPenghasilan">
                                    <Form.Label>Penghasilan per bulan</Form.Label>
                                    <div className="d-flex align-items-center">
                                        <Form.Control
                                            type="text"
                                            name="penghasilan"
                                            value={editData.penghasilan}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/[^0-9]/g, ""); // Hanya angka
                                                const formattedValue = new Intl.NumberFormat("id-ID").format(value); // Format angka
                                                setEditData({ ...editData, penghasilan: value });
                                                setFormattedPenghasilan(formattedValue); // Simpan ke state format label
                                            }}
                                            style={{ width: "100%" }} // Pastikan input memiliki lebar penuh
                                        />
                                        <span
                                            className="ml-3 text-muted"
                                            style={{
                                                width: "100%", // Sama panjang dengan input
                                                textAlign: "right", // Rata kanan untuk angka
                                            }}
                                        >
                                            {formattedPenghasilan || "0"}
                                        </span>
                                    </div>
                                </Form.Group>



                                <Form.Group controlId="formRekeningTabungan">
                                    <Form.Label>Memiliki Rekening Tabungan</Form.Label>
                                    <Form.Select
                                        name="memilikiTabungan"
                                        value={editData.memilikiTabungan}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="formCatatan">
                                    <Form.Label>Sebab data tidak bisa dilanjutkan</Form.Label>
                                    <Form.Select
                                        name="catatan"
                                        value={editData.catatan}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">Sudah Meninggal</option>
                                        <option value="2">Sudah Pindah</option>
                                        <option value="3">Tidak Ditemukan</option>
                                        <option value="4">Lainnya</option>
                                    </Form.Select>
                                    {editData.catatan === "4" && (
                                        <Form.Group controlId="formCatatanLainnya">
                                            <Form.Label>Catatan Lainnya</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="catatanLainnya"
                                                maxLength="250"
                                                placeholder="Masukkan catatan lainnya (maks. 250 karakter)"
                                                value={editData.catatanLainnya || ""}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    )}
                                </Form.Group>



                            </Form>
                        </Tab>

                        <Tab eventKey="third" title="Kepemilikan Rumah">
                            <Form>
                                {/* Status Kepemilikan Bangunan */}
                                <Form.Group controlId="formStatusKepemilikan">
                                    <Form.Label>Status Kepemilikan Bangunan tempat tinggal yang ditempati </Form.Label>
                                    <Form.Select
                                        name="statusKepemilikanRumah"
                                        value={editData.statusKepemilikanRumah}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Milik Sendiri</option>
                                        <option value="2">2. Kontrak/Sewa</option>
                                        <option value="3">3. Bebas Sewa</option>
                                        <option value="4">4. Menumpang</option>
                                        <option value="5">5. Dinas</option>
                                        <option value="6">6. Lainnya</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Jenis Bukti Kepemilikan */}
                                <Form.Group controlId="formJenisBuktiKepemilikan">
                                    <Form.Label>(Jika Milik Sendiri) Apa jenis bukti kepemilikan tanah bangunan tempat tinggal ini </Form.Label>
                                    <Form.Select
                                        name="buktiKepemilikanRumah"
                                        value={editData.buktiKepemilikanRumah}
                                        onChange={handleInputChange}
                                        disabled={editData.statusKepemilikanRumah !== '1'} // Kondisi disable

                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. SHM atas Nama Anggota Keluarga</option>
                                        <option value="2">2. SHM bukan a.n Anggota Keluarga dengan perjanjian pemanfaatan tertulis</option>
                                        <option value="3">3. SHM bukan a.n Anggota Keluarga tanpa perjanjian pemanfaatan tertulis</option>
                                        <option value="4">4. Sertifikat selain SHM (SHGB, SHSRS)</option>
                                        <option value="5">5. Surat Bukti Lainnya (Girik, Letter C, dll)</option>
                                        <option value="6">6. Tidak Punya</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Jumlah Penghuni */}
                                <Form.Group controlId="formJumlahPenghuni">
                                    <Form.Label>Jumlah Penghuni (masukkan jumlah)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="jumlahPenghuni"
                                        value={editData.jumlahPenghuni}
                                        onChange={handleInputChange}
                                    // disabled={editData.statusKepemilikanRumah !== '1'} // Kondisi disable
                                    />
                                </Form.Group>

                                {/* Luas Tanah */}
                                <Form.Group controlId="formLuasTanah">
                                    <Form.Label>Luas Tanah (m2)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="luasTanah"
                                        value={editData.luasTanah}
                                        onChange={handleInputChange}
                                    // disabled={editData.statusKepemilikanRumah !== '1'} // Kondisi disable
                                    />
                                </Form.Group>

                                {/* Luas Bangunan */}
                                <Form.Group controlId="formLuasBangunan">
                                    <Form.Label>Luas Bangunan Rumah (m2)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="luasBangunan"
                                        value={editData.luasBangunan}
                                        onChange={handleInputChange}
                                    // disabled={editData.statusKepemilikanRumah !== '1'} // Kondisi disable
                                    />
                                </Form.Group>

                                {/* Jenis Atap */}
                                <Form.Group controlId="formJenisAtap">
                                    <Form.Label>Jenis Atap</Form.Label>
                                    <Form.Select
                                        name="jenisAtap"
                                        value={editData.jenisAtap}
                                        onChange={handleInputChange}
                                    // disabled={editData.statusKepemilikanRumah !== '1'} // Kondisi disable
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Beton</option>
                                        <option value="2">2. Genteng</option>
                                        <option value="3">3. Asbes/Seng</option>
                                        <option value="4">4. Kayu/Sirap</option>
                                        <option value="5">5. Bambu</option>
                                        <option value="6">6. Jerami/ijuk/rumbia/daun-daunan</option>
                                        <option value="7">7. Lainnya</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Jenis Dinding */}
                                <Form.Group controlId="formJenisDinding">
                                    <Form.Label>Jenis Dinding</Form.Label>
                                    <Form.Select
                                        name="jenisDinding"
                                        value={editData.jenisDinding}
                                        onChange={handleInputChange}
                                    // disabled={editData.statusKepemilikanRumah !== '1'} // Kondisi disable
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Tembok</option>
                                        <option value="2">2. Kayu/Papan</option>
                                        <option value="3">3. Seng</option>
                                        <option value="4">4. Bambu</option>
                                        <option value="5">5. Lainnya</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Jenis Lantai */}
                                <Form.Group controlId="formJenisLantai">
                                    <Form.Label>Jenis Lantai</Form.Label>
                                    <Form.Select
                                        name="jenisLantai"
                                        value={editData.jenisLantai}
                                        onChange={handleInputChange}
                                    // disabled={editData.statusKepemilikanRumah !== '1'} // Kondisi disable
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Keramik/Granit/Marmer/Ubin/Tegel/Teraso</option>
                                        <option value="2">2. Semen</option>
                                        <option value="3">3. Kayu/Papan</option>
                                        <option value="4">4. Bambu</option>
                                        <option value="5">5. Tanah</option>
                                        <option value="6">6. Lainnya</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Penerangan */}
                                <Form.Group controlId="formPenerangan">
                                    <Form.Label>Penerangan</Form.Label>
                                    <Form.Select
                                        name="penerangan"
                                        value={editData.penerangan}
                                        onChange={handleInputChange}
                                    // disabled={editData.statusKepemilikanRumah !== '1'} // Kondisi disable
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="6">Listrik Pribadi 450 Watt</option>
                                        <option value="1">Listrik Pribadi 900 Watt</option>
                                        <option value="2">Listrik Pribadi diatas 900 Watt</option>
                                        <option value="3">Genset/Solar Cell</option>
                                        <option value="4">Listrik Bersama</option>
                                        <option value="5">Non Listrik</option>

                                    </Form.Select>
                                </Form.Group>

                                {/* Air Minum */}
                                <Form.Group controlId="formAirMinum">
                                    <Form.Label>Air Minum</Form.Label>
                                    <Form.Select
                                        name="airMinum"
                                        value={editData.airMinum}
                                        onChange={handleInputChange}
                                    // disabled={editData.statusKepemilikanRumah !== '1'} // Kondisi disable
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Air Kemasan/Isi Ulang</option>
                                        <option value="2">2. Ledeng/PAM</option>
                                        <option value="3">3. Sumur BOR</option>
                                        <option value="4">4. Sumur Terlindungi</option>
                                        <option value="5">5. Sumur Tidak Terlindungi</option>
                                        <option value="6">6. Air Permukaan (Sungai, Danau, dll)</option>
                                        <option value="7">7. Air Hujan</option>
                                        <option value="8">8. Lainnya</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Jenis Kloset */}
                                <Form.Group controlId="formJenisKloset">
                                    <Form.Label>Jenis Kloset</Form.Label>
                                    <Form.Select
                                        name="jenisKloset"
                                        value={editData.jenisKloset}
                                        onChange={handleInputChange}
                                    // disabled={editData.statusKepemilikanRumah !== '1'} // Kondisi disable
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya dengan Septic Tank</option>
                                        <option value="2">2. Ya tanpa Septic Tank</option>
                                        <option value="3">3. Tidak Jamban Umum/Bersama</option>
                                        <option value="4">4. Lainnya</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Bahan Bakar Memasak */}
                                <Form.Group controlId="formBahanBakar">
                                    <Form.Label>Bahan Bakar Memasak</Form.Label>
                                    <Form.Select
                                        name="bbMemasak"
                                        value={editData.bbMemasak}
                                        onChange={handleInputChange}
                                    // disabled={editData.statusKepemilikanRumah !== '1'} // Kondisi disable
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Listrik/Gas</option>
                                        <option value="2">2. Minyak Tanah</option>
                                        <option value="3">3. Arang/Kayu</option>
                                        <option value="4">4. Lainnya</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Kelayakan Konstruksi */}
                                {/* <Form.Group controlId="formKelayakanKonstruksi">
                                    <Form.Label>Kelayakan Konstruksi</Form.Label>
                                    <Form.Select
                                        name="layakKonstruksi"
                                        value={editData.layakKonstruksi}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group> */}

                                {/* Kelayakan Kepadatan Ruang */}
                                {/* <Form.Group controlId="formKepadatanRuang">
                                    <Form.Label>Kelayakan Kepadatan Ruang (Densitas)</Form.Label>
                                    <Form.Select
                                        name="layakDensitas"
                                        value={editData.layakDensitas}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group> */}

                                {/* Kelayakan Air Minum */}
                                {/* <Form.Group controlId="formKelayakanAirMinum">
                                    <Form.Label>Kelayakan Air Minum</Form.Label>
                                    <Form.Select
                                        name="layakAirminum"
                                        value={editData.layakAirminum}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group> */}

                                {/* Kelayakan Sanitasi */}
                                {/* <Form.Group controlId="formKelayakanSanitasi">
                                    <Form.Label>Kelayakan Sanitasi</Form.Label>
                                    <Form.Select
                                        name="layakSanitasi"
                                        value={editData.layakSanitasi}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group> */}

                                {/* Memiliki Aset Lahan di Lokasi Lain */}
                                <Form.Group controlId="formAsetLahan">
                                    <Form.Label>Memiliki Aset Lahan dengan status Milik Sendiri di Lokasi Lain</Form.Label>
                                    <Form.Select
                                        name="asetLahanlain"
                                        value={editData.asetLahanlain}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Memiliki Rumah di Lokasi Lain */}
                                <Form.Group controlId="formRumahLain">
                                    <Form.Label>Memiliki Rumah di Lokasi Lain</Form.Label>
                                    <Form.Select
                                        name="asetRumahlain"
                                        value={editData.asetRumahlain}
                                        onChange={handleInputChange}

                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>
                            </Form>
                        </Tab>

                        <Tab eventKey="fourth" title="Peminatan Program">
                            <Form>
                                {/* Minat Program Pembiayaan Perumahan */}
                                <Form.Group controlId="formMinatPembiayaan">
                                    <Form.Label>Minat Program Pembiayaan Perumahan</Form.Label>
                                    <Form.Select
                                        name="minatProgrampembiayaan"
                                        value={editData.minatProgrampembiayaan}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Jenis Peminatan Rumah */}
                                <Form.Group controlId="formJenisPeminatanRumah">
                                    <Form.Label>Jenis Peminatan Rumah</Form.Label>
                                    <Form.Select
                                        name="jenisPeminatanrumah"
                                        value={editData.jenisPeminatanrumah}
                                        onChange={handleInputChange}
                                        disabled={editData.minatProgrampembiayaan === "2"}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Milik</option>
                                        <option value="2">2. Sewa</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Pilih Program Perumahan */}
                                <Form.Group controlId="formPilihProgramPerumahan">
                                    <Form.Label>Pilih Program Perumahan</Form.Label>
                                    <Form.Select
                                        name="programPerumahan"
                                        value={editData.programPerumahan}
                                        onChange={handleInputChange}
                                        disabled={editData.minatProgrampembiayaan === "2"}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        {editData.jenisPeminatanrumah === "2" ? (
                                            <>
                                                <option value="4">Sewa Hunian</option>
                                            </>
                                        ) : editData.jenisPeminatanrumah === "1" ? (
                                            <>
                                                <option value="1">Pembiayaan Pemilikan Rumah</option>
                                                <option value="2">Pembiayaan Pembangunan Rumah</option>
                                                <option value="3">Pembiayaan Perbaikan Rumah</option>
                                                <option value="5">Pembiayaan Pemilikan Rusun</option>
                                            </>
                                        ) : (
                                            <option value="">Pilih Opsi</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>


                                {/* Input Dynamic Form Based on Program Selection */}
                                {editData.programPerumahan === "2" && (
                                    <Form.Group controlId="formSwadayaMasyarakat">
                                        <Form.Label>Swadaya Masyarakat</Form.Label>

                                        <Form.Check
                                            type="checkbox"
                                            label="Tabungan Uang"
                                            name="pembangunanTabunganUang"
                                            checked={editData.pembangunanTabunganUang}
                                            onChange={handleInputChange}
                                        />

                                        <Form.Check
                                            type="checkbox"
                                            label="Tabungan Material"
                                            name="pembangunanTabunganMaterial"
                                            checked={editData.pembangunanTabunganMaterial}
                                            onChange={handleInputChange}
                                        />

                                        <Form.Check
                                            type="checkbox"
                                            label="Tenaga Kerja"
                                            name="pembangunanTenagaKerja"
                                            checked={editData.pembangunanTenagaKerja}
                                            onChange={handleInputChange}
                                        />

                                        <Form.Check
                                            type="checkbox"
                                            label="Tidak Ada Swadaya Masyarakat"
                                            name="pembangunanTidakAdaSwadayaMasyarakat"
                                            checked={editData.pembangunanTidakAdaSwadayaMasyarakat}
                                            onChange={handleInputChange}
                                        />


                                    </Form.Group>
                                )}


                                {editData.programPerumahan === "3" && (
                                    <>
                                        <Form.Group controlId="formJenisPerbaikan">
                                            <Form.Label>Jenis Perbaikan</Form.Label>
                                            <Form.Check
                                                type="checkbox"
                                                label="Perbaikan Rumah Existing"
                                                name="perbaikanRumahExisting"
                                                checked={editData.perbaikanRumahExisting}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                label="Penambahan Ruangan/Luasan"
                                                name="penambahanRuanganLuasan"
                                                checked={editData.penambahanRuanganLuasan}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                label="Perbaikan Lainnya"
                                                name="jenisPerbaikanLainnya"
                                                checked={editData.jenisPerbaikanLainnya}
                                                onChange={handleInputChange}
                                            />

                                        </Form.Group>

                                        <Form.Group controlId="formSwadayaMasyarakat">
                                            <Form.Label>Swadaya Masyarakat</Form.Label>
                                            <Form.Check
                                                type="checkbox"
                                                label="Tabungan Uang"
                                                name="perbaikanTabunganUang"
                                                checked={editData.perbaikanTabunganUang}
                                                onChange={handleInputChange}
                                            />

                                            <Form.Check
                                                type="checkbox"
                                                label="Tabungan Material"
                                                name="perbaikanTabunganMaterial"
                                                checked={editData.perbaikanTabunganMaterial}
                                                onChange={handleInputChange}
                                            />

                                            <Form.Check
                                                type="checkbox"
                                                label="Tenaga Kerja"
                                                name="perbaikanTenagaKerja"
                                                checked={editData.perbaikanTenagaKerja}
                                                onChange={handleInputChange}
                                            />

                                            <Form.Check
                                                type="checkbox"
                                                label="Tidak Ada Swadaya"
                                                name="perbaikanTidakAdaSwadayaMasyarakat"
                                                checked={editData.perbaikanTidakAdaSwadayaMasyarakat}
                                                onChange={handleInputChange}
                                            />



                                        </Form.Group>
                                    </>
                                )}


                                {/* Pilih Bank Pelaksana */}
                                <Form.Group controlId="formPilihBankPelaksana">
                                    <Form.Label>Pilih Bank Pelaksana</Form.Label>
                                    <Form.Select
                                        name="pilihanBankpelaksana"
                                        value={editData.pilihanBankpelaksana}
                                        onChange={handleInputChange}
                                        disabled={editData.minatProgrampembiayaan === "2"}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Bank BTN</option>
                                        <option value="2">2. Bank BRI</option>
                                        <option value="3">3. Bank Lainnya sebutkan</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Input Box for Other Bank Name */}
                                {editData.pilihanBankpelaksana === "3" && (
                                    <Form.Group controlId="formNamaBankLain">
                                        <Form.Label>Nama Bank Lain</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="namaBankLain"
                                            value={editData.namaBankLain}
                                            onChange={handleInputChange}
                                            placeholder="Sebutkan nama bank lain"
                                        />
                                    </Form.Group>
                                )}

                                {/* Kesediaan Besar Cicilan */}
                                <Form.Group controlId="formCicilan">
                                    <Form.Label>Kemampuan Mencicil</Form.Label>
                                    <Form.Select
                                        name="besaranCicilan"
                                        value={editData.besaranCicilan}
                                        onChange={handleInputChange}
                                        disabled={editData.minatProgrampembiayaan === "2"}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">&lt; 500rb</option>
                                        <option value="2">500 ribu - 750 ribu</option>
                                        <option value="3">750 ribu - 1 juta</option>
                                        <option value="4">&gt; 1 juta</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Rencana Program */}
                                <Form.Group controlId="formRencanaProgram">
                                    <Form.Label>Rencana Program </Form.Label>
                                    <Form.Select
                                        name="rencanaProgram"
                                        value={editData.rencanaProgram}
                                        onChange={handleInputChange}
                                        disabled={editData.minatProgrampembiayaan === "2"}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">2025</option>
                                        <option value="2">2026</option>
                                        <option value="3">2027</option>
                                        <option value="4">Belum Tahu Kapan</option>
                                    </Form.Select>
                                </Form.Group>
                            </Form>
                        </Tab>


                        <Tab eventKey="fifth" title="Kawasan Iklim"
                            disabled={
                                editData.jenisPeminatanrumah === "2" ||
                                editData.programPerumahan === "1" ||
                                editData.programPerumahan === "5"
                            }
                        >
                            <Form>
                                {/* Sarana Transportasi Umum */}
                                <Form.Group controlId="formSaranaTransportasi">
                                    <Form.Label>Sarana Transportasi</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        label="Angkutan Umum"
                                        name="angkutanUmum"
                                        checked={editData.angkutanUmum}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Terminal"
                                        name="terminal"
                                        checked={editData.terminal}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Stasiun"
                                        name="stasiun"
                                        checked={editData.stasiun}
                                        onChange={handleInputChange}
                                    />

                                    <Form.Check
                                        type="checkbox"
                                        label="Gerbang Tol"
                                        name="gerbangTol"
                                        checked={editData.gerbangTol}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="SPBU"
                                        name="spbu"
                                        checked={editData.spbu}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                {/* Sarana Pendidikan */}
                                <Form.Group controlId="formSaranaPendidikan">
                                    <Form.Label>Sarana Pendidikan</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        label="TK"
                                        name="tk"
                                        checked={editData.tk}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="SD"
                                        name="sd"
                                        checked={editData.sd}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="SLTP"
                                        name="sltp"
                                        checked={editData.sltp}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="SLTA"
                                        name="slta"
                                        checked={editData.slta}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="universitas"
                                        name="universitas"
                                        checked={editData.universitas}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Lainnya"
                                        name="pendidikanLainnya"
                                        checked={editData.pendidikanLainnya}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                {/* Sarana Tempat Ibadah */}
                                <Form.Group controlId="formSaranaIbadah">
                                    <Form.Label>Sarana Tempat Ibadah</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        label="Masjid/Musholla"
                                        name="masjidMusholla"
                                        checked={editData.masjidMusholla}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Gereja"
                                        name="gereja"
                                        checked={editData.gereja}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Vihara"
                                        name="vihara"
                                        checked={editData.vihara}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Klenteng"
                                        name="klenteng"
                                        checked={editData.klenteng}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Lainnya"
                                        name="tempatIbadahLainnya"
                                        checked={editData.tempatIbadahLainnya}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                {/* Sarana Lainnya */}
                                <Form.Group controlId="formSaranaLainnya">
                                    <Form.Label>Sarana Lainnya</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        label="Pasar"
                                        name="pasar"
                                        checked={editData.pasar}
                                        onChange={handleInputChange}
                                    />

                                    <Form.Check
                                        type="checkbox"
                                        label="Kesehatan"
                                        name="kesehatan"
                                        checked={editData.kesehatan}
                                        onChange={handleInputChange}
                                    />


                                    <Form.Check
                                        type="checkbox"
                                        label="Bank"
                                        name="bank"
                                        checked={editData.bank}
                                        onChange={handleInputChange}
                                    />

                                </Form.Group>


                                {/* Kondisi Iklim dalam 3 Tahun Terakhir */}
                                <Form.Group controlId="formGenanganAir">
                                    <Form.Label>Apakah pernah terjadi Genangan Air dalam kurun waktu 6 bulan - 1 tahun terakhir ?</Form.Label>
                                    <Form.Select
                                        name="terjadiGenanganAir"
                                        value={editData.terjadiGenanganAir}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="formBanjir">
                                    <Form.Label>Apakah pernah terjadi Banjir dalam kurun waktu 6 bulan - 1 tahun terakhir ?</Form.Label>
                                    <Form.Select
                                        name="terjadiBanjir"
                                        value={editData.terjadiBanjir}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="formPutingBeliung">
                                    <Form.Label>Apakah pernah terjadi Angin Puting Beliung dalam kurun waktu 6 bulan - 1 tahun terakhir ?</Form.Label>
                                    <Form.Select
                                        name="terjadiPutingBeliung"
                                        value={editData.terjadiPutingBeliung}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="formKeretakanTanah">
                                    <Form.Label>Apakah pernah terjadi Keretakan Tanah dalam kurun waktu 6 bulan - 1 tahun terakhir ?</Form.Label>
                                    <Form.Select
                                        name="terjadiKeretakanTanah"
                                        value={editData.terjadiKeretakanTanah}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="formLongsor">
                                    <Form.Label>Apakah pernah terjadi Longsor dalam kurun waktu 6 bulan - 1 tahun terakhir ?</Form.Label>
                                    <Form.Select
                                        name="terjadiLongsor"
                                        value={editData.terjadiLongsor}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="formGempaBumi">
                                    <Form.Label>Apakah pernah terjadi Gempa Bumi dalam kurun waktu 6 bulan - 1 tahun terakhir ?</Form.Label>
                                    <Form.Select
                                        name="terjadiGempaBumi"
                                        value={editData.terjadiGempaBumi}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>
                            </Form>


                        </Tab>

                        <Tab eventKey="sixth" title="Photo">
                            <Form>
                                {/* Keterangan Peminatan Program */}
                                <Form.Group>
                                    <Form.Label>Keterangan Peminatan Program</Form.Label>
                                    <p>{editData.minatProgrampembiayaan === "1" ? "Mengikuti Program Pembiayaan Perumahan" : "Tidak Mengikuti Program Pembiayaan Perumahan"}</p>
                                    <p>{editData.jenisPeminatanrumah === "1" ? "Jenis Peminatan: Milik" : editData.jenisPeminatanrumah === "2" ? "Jenis Peminatan: Sewa" : ""}</p>
                                    <p>{editData.programPerumahan === "1" ? "Program Terpilih: Pembiayaan Pemilikan Rumah" :
                                        editData.programPerumahan === "2" ? "Program Terpilih: Pembiayaan Pembangunan Rumah" :
                                            editData.programPerumahan === "3" ? "Program Terpilih: Pembiayaan Perbaikan Rumah" :
                                                editData.programPerumahan === "4" ? "Program Terpilih: Sewa Hunian" :
                                                    editData.programPerumahan === "5" ? "Program Terpilih: Pembiayaan Pemilikan Rusun" : "Belum memilih program"}</p>
                                </Form.Group>
                                <Row>
                                    <Col md={6}>
                                        {/* Photo Diri */}
                                        <Form.Group controlId="formPhotoDiri">
                                            <Form.Label>Photo Diri</Form.Label>
                                            <Form.Control
                                                type="file"
                                                accept="image/*"
                                                name="photoDiri"
                                                onChange={(e) => handleImageChange(e, 'photoDiri')}
                                            />
                                            <img
                                                src={previewImages.photoDiri}
                                                alt="Preview Photo Diri"
                                                style={{ width: '250px', marginTop: '10px' }}
                                            />
                                            {geoData.photoDiri ? (
                                                <p>
                                                    Location: {geoData.photoDiri.lon}, {geoData.photoDiri.lat}{' '}
                                                    <a
                                                        href={`https://www.google.com/maps?q=${geoData.photoDiri.lat},${geoData.photoDiri.lon}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ marginLeft: '5px' }}
                                                    >
                                                        <i className="fa fa-map-marker" style={{ color: 'red', cursor: 'pointer' }} />
                                                    </a>
                                                </p>
                                            ) : (

                                                editData.koordinatFotoDiri && (
                                                    <p>
                                                        Location: {editData.koordinatFotoDiri}{' '}
                                                        {(() => {
                                                            try {
                                                                // Memisahkan koordinat berdasarkan koma
                                                                const coords = editData.koordinatFotoDiri.split(',').map((coord) => coord.trim());
                                                                if (coords.length === 2) {
                                                                    const latitude = coords[1];
                                                                    const longitude = coords[0];
                                                                    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
                                                                    return (
                                                                        <a
                                                                            href={googleMapsUrl}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{ marginLeft: '5px' }}
                                                                        >
                                                                            <i className="fa fa-map-marker" style={{ color: 'red', cursor: 'pointer' }} />
                                                                        </a>
                                                                    );
                                                                }
                                                                return <span style={{ color: 'red', marginLeft: '5px' }}>Invalid coordinates</span>;
                                                            } catch (error) {
                                                                return <span style={{ color: 'red', marginLeft: '5px' }}>Error parsing coordinates</span>;
                                                            }
                                                        })()}
                                                    </p>
                                                )

                                            )}
                                        </Form.Group>

                                        {/* Photo Sekitar */}
                                        <Form.Group controlId="formPhotoSekitar">
                                            <Form.Label>Photo Sekitar</Form.Label>
                                            <Form.Control
                                                type="file"
                                                accept="image/*"
                                                name="photoSekitar"
                                                onChange={(e) => handleImageChange(e, 'photoSekitar')}
                                            />
                                            <img
                                                src={previewImages.photoSekitar}
                                                alt="Preview Photo Sekitar"
                                                style={{ width: '250px', marginTop: '10px' }}
                                            />
                                            {geoData.photoSekitar ? (
                                                <p>
                                                    Location: {geoData.photoSekitar.lon}, {geoData.photoSekitar.lat}{' '}
                                                    <a
                                                        href={`https://www.google.com/maps?q=${geoData.photoSekitar.lat},${geoData.photoSekitar.lon}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ marginLeft: '5px' }}
                                                    >
                                                        <i className="fa fa-map-marker" style={{ color: 'red', cursor: 'pointer' }} />
                                                    </a>
                                                </p>
                                            ) : (
                                                editData.koordinatFotoSekitar && (
                                                    <p>
                                                        Location: {editData.koordinatFotoSekitar}{' '}
                                                        {(() => {
                                                            try {
                                                                // Memisahkan koordinat berdasarkan koma
                                                                const coords = editData.koordinatFotoSekitar.split(',').map((coord) => coord.trim());
                                                                if (coords.length === 2) {
                                                                    const latitude = coords[1];
                                                                    const longitude = coords[0];
                                                                    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
                                                                    return (
                                                                        <a
                                                                            href={googleMapsUrl}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{ marginLeft: '5px' }}
                                                                        >
                                                                            <i className="fa fa-map-marker" style={{ color: 'red', cursor: 'pointer' }} />
                                                                        </a>
                                                                    );
                                                                }
                                                                return <span style={{ color: 'red', marginLeft: '5px' }}>Invalid coordinates</span>;
                                                            } catch (error) {
                                                                return <span style={{ color: 'red', marginLeft: '5px' }}>Error parsing coordinates</span>;
                                                            }
                                                        })()}
                                                    </p>
                                                )

                                            )}
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>

                                        {/* Photo Rumah */}
                                        <Form.Group controlId="formPhotoRumah">
                                            <Form.Label>Photo Rumah</Form.Label>
                                            <Form.Control
                                                type="file"
                                                accept="image/*"
                                                name="photoRumah"
                                                onChange={(e) => handleImageChange(e, 'photoRumah')}
                                            />
                                            <img
                                                src={previewImages.photoRumah}
                                                alt="Preview Photo Rumah"
                                                style={{ width: '250px', marginTop: '10px' }}
                                            />
                                            {geoData.photoRumah ? (
                                                <p>
                                                    Location: {geoData.photoRumah.lon}, {geoData.photoRumah.lat}{' '}
                                                    <a
                                                        href={`https://www.google.com/maps?q=${geoData.photoRumah.lon},${geoData.photoRumah.lat}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ marginLeft: '5px' }}
                                                    >
                                                        <i className="fa fa-map-marker" style={{ color: 'red', cursor: 'pointer' }} />
                                                    </a>
                                                </p>
                                            ) : (
                                                editData.koordinatFotoRumah && (
                                                    <p>
                                                        Location: {editData.koordinatFotoRumah}{' '}
                                                        {(() => {
                                                            try {
                                                                // Memisahkan koordinat berdasarkan koma
                                                                const coords = editData.koordinatFotoRumah.split(',').map((coord) => coord.trim());
                                                                if (coords.length === 2) {
                                                                    const latitude = coords[1];
                                                                    const longitude = coords[0];
                                                                    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
                                                                    return (
                                                                        <a
                                                                            href={googleMapsUrl}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{ marginLeft: '5px' }}
                                                                        >
                                                                            <i className="fa fa-map-marker" style={{ color: 'red', cursor: 'pointer' }} />
                                                                        </a>
                                                                    );
                                                                }
                                                                return <span style={{ color: 'red', marginLeft: '5px' }}>Invalid coordinates</span>;
                                                            } catch (error) {
                                                                return <span style={{ color: 'red', marginLeft: '5px' }}>Error parsing coordinates</span>;
                                                            }
                                                        })()}
                                                    </p>
                                                )

                                            )}
                                        </Form.Group>

                                        {/* Photo Lahan */}
                                        <Form.Group controlId="formPhotoLahan">
                                            <Form.Label>Photo Lahan</Form.Label>
                                            <Form.Control
                                                type="file"
                                                accept="image/*"
                                                name="photoLahan"
                                                onChange={(e) => handleImageChange(e, 'photoLahan')}
                                            />
                                            <img
                                                src={previewImages.photoLahan}
                                                alt="Preview Photo Lahan"
                                                style={{ width: '250px', marginTop: '10px' }}
                                            />
                                            {geoData.photoLahan ? (
                                                <p>
                                                    Location: {geoData.photoLahan.lon}, {geoData.photoLahan.lat}{' '}
                                                    <a
                                                        href={`https://www.google.com/maps?q=${geoData.photoLahan.lat},${geoData.photoLahan.lon}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ marginLeft: '5px' }}
                                                    >
                                                        <i className="fa fa-map-marker" style={{ color: 'red', cursor: 'pointer' }} />
                                                    </a>
                                                </p>
                                            ) : (

                                                editData.koordinatFotoLahan && (
                                                    <p>
                                                        Location: {editData.koordinatFotoLahan}{' '}
                                                        {(() => {
                                                            try {
                                                                // Memisahkan koordinat berdasarkan koma
                                                                const coords = editData.koordinatFotoLahan.split(',').map((coord) => coord.trim());
                                                                if (coords.length === 2) {
                                                                    const latitude = coords[1];
                                                                    const longitude = coords[0];
                                                                    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
                                                                    return (
                                                                        <a
                                                                            href={googleMapsUrl}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{ marginLeft: '5px' }}
                                                                        >
                                                                            <i className="fa fa-map-marker" style={{ color: 'red', cursor: 'pointer' }} />
                                                                        </a>
                                                                    );
                                                                }
                                                                return <span style={{ color: 'red', marginLeft: '5px' }}>Invalid coordinates</span>;
                                                            } catch (error) {
                                                                return <span style={{ color: 'red', marginLeft: '5px' }}>Error parsing coordinates</span>;
                                                            }
                                                        })()}
                                                    </p>
                                                )

                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Tab>

                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>

                    {!viewOnly && (
                        <Button variant="primary" onClick={handleModalSave} disabled={viewOnly || loading}>
                            {loading ? (
                                <>
                                    <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                    Simpan...
                                </>
                            ) : (
                                'Update Data'
                            )}
                        </Button>
                    )}


                </Modal.Footer>


            </Modal>


            {/* Modal for Rejection Reason */}
            <Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Alasan Revisi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea
                        className="form-control"
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                        placeholder="Input alasan revisi..."
                    ></textarea>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRejectModal(false)}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleRejectSubmit}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Modal untuk pengisian revisi */}
            <Modal show={showRevisiModal} size="xl" backdrop="static" onHide={() => setShowRevisiModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Revisi Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Riwayat Revisi</Form.Label>
                    {loadingRevisi ? (
                        <p>Loading...</p>
                    ) : revisiList.length > 0 ? (
                        <div className="table-responsive">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Revisi</th>
                                        <th>Response</th>
                                        <th>Tanggal Dibuat</th>
                                        <th>Tanggal Respon</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {revisiList.map((revisi, index) => (
                                        <tr key={revisi.id}>
                                            <td>{index + 1}</td>
                                            <td>{revisi.content}</td>
                                            <td>{revisi.response || "Belum ada respon"}</td>
                                            <td>{new Date(revisi.createdAt).toLocaleDateString()}</td>
                                            <td>
                                                {revisi.responseAt
                                                    ? new Date(revisi.responseAt).toLocaleDateString()
                                                    : "Belum ada respon"}
                                            </td>
                                            <td>
                                                {revisi.sudahDirespon ? (
                                                    <span className="badge bg-success">Direspon</span>
                                                ) : (
                                                    <span className="badge bg-warning">Belum Direspon</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    ) : (
                        <p>Tidak ada catatan revisi untuk quesioner ini.</p>
                    )}

                    <Form>
                        <Form.Group controlId="content">
                            <Form.Label>Catatan Revisi</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="content"
                                value={formRevisiData.content}
                                onChange={handleInputRevisiChange}
                                placeholder="Masukkan catatan revisi"
                                required
                            />
                        </Form.Group>
                        {/* <Form.Group controlId="response">
                            <Form.Label>Tanggapan (Opsional)</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                name="response"
                                value={formRevisiData.response}
                                onChange={handleInputRevisiChange}
                                placeholder="Masukkan tanggapan"
                            />
                        </Form.Group>
                        <Form.Group controlId="sudahDirespon">
                            <Form.Check
                                type="checkbox"
                                name="sudahDirespon"
                                label="Sudah Direspon"
                                checked={formRevisiData.sudahDirespon}
                                onChange={(e) =>
                                    setFormRevisiData({
                                        ...formRevisiData,
                                        sudahDirespon: e.target.checked,
                                    })
                                }
                            />
                        </Form.Group> */}
                    </Form>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRevisiModal(false)}>
                        Batal
                    </Button>
                    <Button variant="primary" onClick={handleRevisiSubmit}>
                        Simpan Revisi
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    );
};

export default PemdaApprovalPendataan;

